import React from "react"

// Import Components
import Layout from "../components/layout"
import SEO from "../components/seo"
import CollapseCareers from "../components/collapseCareers"

const Careers = () => {

  return (
    <Layout>
      <SEO title="Join our team - Rooam" />
      <div className="section more-top no-bottom">
        <div className="container medium text-center">
          <h1 className="text-white no-top-bottom">Join our team</h1>
        </div>
      </div>

      <div className="section less-top">
        <CollapseCareers/>
      </div>
    </Layout>
  )
}

export default Careers

import React, { Component } from 'react';

// Import Reveal Effects
import Fade from 'react-reveal/Fade';

class CollapseCareers extends Component {

  state = {
    isCollapseOneActive: false,
    isCollapseTwoActive: false,
    isCollapseThreeActive: false,
  }
  
  render() {

    const isOneActive = this.state.isCollapseOneActive;
    const isTwoActive = this.state.isCollapseTwoActive;
    const isThreeActive = this.state.isCollapseThreeActive;

    return (
      <div className="section no-top-bottom">
        <div className="container medium">
          <Fade bottom distance={'1em'} duration={500} cascade>
            <ul className="collapse no-bottom">

              <li className={ isOneActive ? "active" : ""}>
                <div className="collapse-title no-select" onClick={ () => { this.setState({isCollapseOneActive: !isOneActive}); }} onKeyDown={ () => { this.setState({isCollapseOneActive: !isOneActive}); }} role="link" tabIndex={0}>
                  <p className="text-white font-weight-500 no-top-bottom">
                    Sales Consultant
                  </p>
                  <span className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
                  </span>
                </div>
                <div className="collapse-body">
                  <p className="text-light-grey">We are looking to partner with individuals who are excited about how Rooam is revolutionizing payments and enhancing guest experience and security in the hospitality industry. If you have contacts in restaurants, bars, hospitality groups, breweries, or any other establishments to make a warm introduction to Rooam, this is perfect for you.</p>
                  <p className="text-light-grey">The current position is designed for a consultant role, with the possibility for a larger role if the fit is of interest to both Rooam and you.</p>
                  <p className="text-light-grey">If you have interest or questions about the consultant role, commission structure, or would like to make an introduction to your connection, please reach out to <a className="text-link-green" href="mailto:business@rooam.co" target="_blank" rel="noopener noreferrer">business@rooam.co</a>.</p>
                </div>
              </li>

              <li className={ isTwoActive ? "active" : ""}>
                <div className="collapse-title no-select" onClick={ () => { this.setState({isCollapseTwoActive: !isTwoActive}); }} onKeyDown={ () => { this.setState({isCollapseTwoActive: !isTwoActive}); }} role="link" tabIndex={0}>
                  <p className="text-white font-weight-500 no-top-bottom">
                    Senior Java/Groovy Backend Software Engineer
                  </p>
                  <span className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
                  </span>
                </div>
                <div className="collapse-body">
                  <p className="text-light-grey"><strong className="text-white font-weight-500">Duties:</strong><br/>Develop and design distributed web applications in Groovy with help of the Spring Boot framework. Apply best practices into design and implementation of scalable backend web applications using REST API interface and NoSQL databases. Cooperate with other teams, such as backend, mobile and QA. Support developed functionality with unit, integration and functional tests. Work in an agile environment.</p>
                  <p className="text-light-grey"><strong className="text-white font-weight-500">Our Technology Stack:</strong><br/>Groovy, Spring Boot, MongoDB, REST, RabbitMQ, Git, Jenkins, Event based distributed architecture.</p>
                  <p className="text-light-grey"><strong className="text-white font-weight-500">Your Profile:</strong><br/>Extensive experience as a Java/Groovy developer using Spring and Spring data. You are familiar with relational and non-relational (MongoDB) databases as well as with messages queues (RabbitMQ). Self organized, quality, performance and team oriented. Hold a university degree in computer science or a related field.</p>
                  <p className="text-light-grey">Apply at <a className="text-link-green" href="mailto:careers@rooam.co" target="_blank" rel="noopener noreferrer">careers@rooam.co</a>.</p>
                </div>
              </li>

              <li className={ isThreeActive ? "active" : ""}>
                <div className="collapse-title no-select" onClick={ () => { this.setState({isCollapseThreeActive: !isThreeActive}); }} onKeyDown={ () => { this.setState({isCollapseThreeActive: !isThreeActive}); }} role="link" tabIndex={0}>
                  <p className="text-white font-weight-500 no-top-bottom">
                    Intern at Rooam
                  </p>
                  <span className="arrow">
                    <svg xmlns="http://www.w3.org/2000/svg" className="svg-icon" viewBox="0 0 24 24"><path d="M0 7.33l2.829-2.83 9.175 9.339 9.167-9.339 2.829 2.83-11.996 12.17z"/></svg>
                  </span>
                </div>
                <div className="collapse-body">
                  <p className="text-light-grey">Rooam is looking for talented engineers, designers, photographers, and creative writing students who share our passion in creating a platform that streamlines nightlife.</p>
                  <p className="text-light-grey">Apply at <a className="text-link-green" href="mailto:careers@rooam.co" target="_blank" rel="noopener noreferrer">careers@rooam.co</a>.</p>
                </div>
              </li>

            </ul>
          </Fade>
        </div>
      </div>
    );
  }
}

export default CollapseCareers

